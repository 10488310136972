import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import auth from '@/auth/index'

require('@/assets/main.scss')

// library.add(faCoffee)
// Vue.component('font-awesome-icon', FontAwesomeIcon)

const ENDPOINT_PATH = process.env.VUE_APP_API_URL

Vue.config.productionTip = false
Vue.use({
  install (Vue) {
  Vue.prototype.$axios = axios.create({
    baseURL: ENDPOINT_PATH
  })
}
})
// Add a request interceptor
Vue.prototype.$axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (auth.isLogged()) {
    config.headers.common.Authorization = `Bearer ${auth.getTokenLogged()}`
  }
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})
Vue.prototype.$axios.interceptors.response.use((response) => {
  if (response.config.parse) {    
      //perform the manipulation here and change the response object
  }
  return response;
}, (error) => {
  if (error.response.status === 401)  auth.closeSession()
  return Promise.reject(error.message);
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
