<template>

  <div id="app">
    <!-- <link rel="stylesheet" href="https://maxcdn.icons8.com/fonts/line-awesome/1.1/css/line-awesome-font-awesome.min.css"/>-->
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css" integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    
   <!-- <header class="hero" v-if="!['LoginView', 'register', 'help'].includes($route.name)">
      <link rel="stylesheet" href="https://maxcdn.icons8.com/fonts/line-awesome/1.1/css/line-awesome-font-awesome.min.css">
      <div class="hero-head">
        <nav
          class="navbar has-shadow"
          role="navigation"
          aria-label="main navigation"
        >
          <div class="navbar-brand">
            <a class="navbar-item is--brand">
              <img
                class="navbar-brand-logo"
                src="@/assets/logo.png"
                alt="Brand logo"
              />
            </a>
            <button class="button navbar-burger" data-target="navMenu">
              <span></span> <span></span> <span></span>
            </button>
          </div>
          <div class="navbar-menu navbar-end" id="navMenu">
            <a class="navbar-item nav-tag">
              <span class="icon is-small">
                <i class="fa fa-envelope-o animated"></i>
              </span>
              <span class="tag is-success counter">2</span>
            </a>
            <a class="navbar-item nav-tag">
              <span class="icon is-small">
                <i class="fa fa-bell-o animated"></i>
              </span>
              <span class="tag is-danger counter">6</span>
            </a>
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">
                <figure class="image is-32x32" style="margin-right: 0.5em">
                  <img
                    src="https://avatars1.githubusercontent.com/u/7221389?v=4&amp;s=32"
                  />
                </figure>
                Admin
              </a>
              <div class="navbar-dropdown is-right">
                <a
                  class="navbar-item"
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span class="icon is-small">
                    <i class="fa fa-user-o"></i> </span
                  >&nbsp; Perfil
                </a>
                <hr class="navbar-divider" />
                <a class="navbar-item" @click.prevent="closeSession()">
                  <span class="icon is-small">
                    <i class="fa fa-power-off"></i> </span
                  >&nbsp; Cerrar Sesión
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
    -->
    <Nav v-if="!['LoginView', 'register', 'help'].includes($route.name)"/>
    <div class="columns">
      <div class="column is-2 is-narrow-mobile is-fullheight section is-hidden-mobile" v-if="!['LoginView', 'register', 'help'].includes($route.name)">
        <Menu />
      </div>
      <Breadcrumb v-if="!['LoginView', 'register', 'help'].includes($route.name) && 1!=1" :itemList='[1, 2, 3]' />
      <div class="column is-10" v-if="!['LoginView', 'register', 'help'].includes($route.name)" :itemList='[1, 2, 3]'>
        <router-view />
      </div>
      <div class="column is-12 hero is-primary is-fullheight" v-else>
        <router-view />
      </div>
    </div>
  </div>
</template>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  color: #2c3e50;
  min-height: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  #menu-toggle:checked + .nav-menu {
    display: block;
  }
}
</style>
<script>
import auth from '@/auth/index'
import Breadcrumb from './components/breadcrumb'
import Menu from './components/menu'
import Nav from './components/nav'
export default {
  components: {
    Breadcrumb,
    Menu,
    Nav
  },
  methods: {
    closeSession() {
      auth.closeSession()
      this.$router.push({ name: 'LoginView' })
    }
  }
}
</script>