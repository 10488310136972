<template>
<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" href="#">
      <img
        class="navbar-brand-logo"
        src="@/assets/brandLogo.png"
        alt="Brand logo"
        width="112" height="28"
      />

    </a>

    <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" id="burgerButton" @click.prevent="burgerClick()" >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu">
    <div class="navbar-start">
      <router-link to="/" class="navbar-item is-hidden-desktop is-hidden-widescreen is-hidden-fullhd">
        Clientes
      </router-link>

      <router-link to="/billings" class="navbar-item is-hidden-desktop is-hidden-widescreen is-hidden-fullhd">
        Facturación
      </router-link>
      <router-link to="#" class="navbar-item is-hidden-desktop is-hidden-widescreen is-hidden-fullhd">
        <span class="icon"><i class="fa fa-gears"></i></span> Configuración
      </router-link> 
      <router-link to="/about" class="navbar-item is-hidden-desktop is-hidden-widescreen is-hidden-fullhd">
        <span class="icon"><i class="fa fa-info"></i></span> Version Prueba
      </router-link> 
    </div>

    <div class="navbar-end">
      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
          <span class="icon"><i class="fas fa-user"></i></span> <!-- Icono de usuario -->
        </a>
        <div class="navbar-dropdown is-right"> <!-- Agregamos la clase "is-right" para desplegar el menú hacia la derecha -->
          <a class="navbar-item" @click.prevent="showModalFunc()">
            <span class="icon"><i class="fas fa-key"></i></span> Cambiar Contraseña <!-- Icono y texto de cambiar contraseña -->
          </a>
          <a class="navbar-item" @click.prevent="closeSession()">
            <span class="icon"><i class="fas fa-sign-out-alt"></i></span> Cerrar Sesión <!-- Icono y texto de cerrar sesión -->
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- <changePassword title='Nuevo Cliente' :visible="showFormModal" @close-modal-func="closeModalFunc" :formName="'companyForm'" @save="changePassword2" :saveLabel="'Cambiar'" :error="error" :strError="strError"/> -->
  <section>
    <div class="modal" id="modalChangePassword">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
        <p class="modal-card-title">Cambiar Constraseña</p>
        <button class="delete"  @click.prevent="closeModalFunc()" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <changePassword />
        </section>
        <footer class="modal-card-foot">
        </footer>
      </div>
    </div>
    </section>
</nav>
</template>

<script>
import auth from '@/auth/index'
import changePassword from '@/components/changePassword.vue'
import ChangePassword from './changePassword.vue'
export default {
  components: { changePassword, ChangePassword },
  data: () => ({
    showFormModal: true,
    error: '',
    strError: ''
  }),
  methods: {
    closeSession() {
      this.$axios
			.post('logout')
			.then(response => {
				const result = response.data
        console.log(result)
        auth.closeSession()
        this.$router.push({ name: 'LoginView' })

			})
			.catch(error => {
        console.log(error)
        auth.closeSession()
        this.$router.push({ name: 'LoginView' })
      } )

      // this.$router.push({ name: 'LoginView' })
      
    },
    changePassword2() {

    },
    burgerClick() {
      let burgerButton = document.querySelector(`#burgerButton`)
      let target = document.querySelector(`#navbarBasicExample`)
      
			burgerButton.classList.toggle('is-active')
      target.classList.toggle('is-active')
      
    },
    showModalFunc() {
      this.showFormModal = true
      var Modal = document.querySelector(`#modalChangePassword`)
      Modal.classList.toggle('is-active')
      // console.log('showModalFunc', this.showFormModal)
    },
    closeModalFunc() {
      var Modal = document.querySelector(`#modalChangePassword`)
      Modal.classList.toggle('is-active')
      this.showFormModal = false
    }
  },
  mounted () {
    /* document.addEventListener('DOMContentLoaded', () => {

      // Get all "navbar-burger" elements
      const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

      // Add a click event on each of them
      $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {
          console.log('here')
          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');

        });
      });

    }); */
  }
}
</script>

<style>
  .navbar-dropdown {
    left: auto;
    right: 0;
  }
</style>