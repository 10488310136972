
<template>
	<aside class="menu">
		
		<p class="menu-label">
			Administración
		</p>
		<ul class="menu-list" v-if="hasPermission('menuCLIENT')">
			<li>
				<a href="#" class="is-active" id="clientMenu" @click.prevent="clickMenu('clientMenu', '/#')">
					<span class="icon"><i class="fa fa-home"></i></span> Clientes
				</a>
			</li>
		</ul>
		<ul class="menu-list" v-if="hasPermission('menuFACT')">
			<li>
				<a href="#" id="invoiceMenu"  @click.prevent="clickMenu('invoiceMenu', '/billings')">
					<span class="icon"><i class="fa-solid fa-file-invoice-dollar"></i></span>Facturación
				</a>
			</li>

			<li v-if="hasPermission('menuCONF')">
				<router-link to="#">
					<span class="icon"><i class="fa fa-gears"></i></span> Configuración
				</router-link> 
			</li>
		</ul>
		
		<p class="menu-label">
			Reportes
		</p>
		<ul class="menu-list">
			<li v-if="hasPermission('menuREP')"><a>Pagos</a></li>
			<li>
				<a href="#" id="aboutMenu" @click.prevent="clickMenu('aboutMenu', '/about')">
					<span class="icon"><i class="fa fa-info"></i></span> Version de Prueba
				</a>
			</li>
		</ul>
	</aside>
</template>
<script>
import auth from '@/auth/index'
export default {
  data: () => ({
		menus: [{ id: 'clientMenu', isActive: true }]
  }),
  methods: {
		hasPermission(code) {
      const result = auth.hasUserPermission(code)
      console.log(result)
      return result
    },
    async clickMenu (id, url) {
			if (!this.menus.find(menu => menu.id === id && menu.isActive))
			{
				await this.menus.filter(menu=>menu.isActive).forEach(element => {
					let menu = document.querySelector(`#` + element.id)
					element.isActive = false
					menu.classList.toggle('is-active')	
				})
			
				if(!this.menus.find(menu => menu.id === id))
					this.menus.push({id: id, isActive: true })
				else{
					this.menus[(this.menus.findIndex(element => element.id === id))].isActive = true
				}
				let menu = document.querySelector(`#` + id)
				menu.classList.toggle('is-active')
				console.log(url)
				
			}
			this.$router.push(url).catch(()=>{})
    }
  }
}
</script>