<template>
  <div class="container">
    <h1 class="title">Clientes</h1>
    <input
      v-model="searchText"
      class="input"
      type="text"
      placeholder="Búsqueda por Rut o Nombre"
    >
    
    <CompanyList v-if="hasPermission('READFORM')" :companies="this.companies" :hasWritePermission="hasPermission('EDITFORM')" />
    <p v-else>No tiene los permisos necesarios </p>
  </div>
</template>

<script>
import CompanyList from '../components/company/CompanyList.vue'
import auth from '@/auth/index'
export default {

  name: 'Home',
  components: {
    CompanyList,
    // Login
  },
  data: () => ({
    companies: [],
    tmpCompanies: [],
    searchText: ''
  }),
  methods: {
    async getClientes () {
      // this.$axios.defaults.withCredentials = true
      this.$axios
      .get('company/getCompanies', { params: { searchParam: this.searchText } })
      .then(response => {
        // console.log(response.data)
        const companies = response.data.companies
        this.companies = companies
      })
      .catch(error => console.log(error))
    },
    hasPermission(code) {
      const result = auth.hasUserPermission(code)
      console.log(result)
      return result
    }
  },
  mounted(){
    this.getClientes()
  },
  watch: { 
		searchText: function() { // watch it
			this.getClientes()
		}
	}
}
</script>
