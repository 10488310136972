import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import auth from '@/auth/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home, meta: { auth: true, breadCrumb: [{ text: 'Home'}] }
  },
  { path: '/company/new', name: 'CompanyNew', component: () => import('../views/CompanyNew.vue'), meta: { auth: true, breadCrumb: [{ text: 'Nuevo Cliente'}] } },
  { path: '/company/:id', name: 'CompanyShow', component: () => import('../views/CompanyShow.vue'), meta: { auth: true, breadCrumb: [{ text: 'Cliente'}] } },
  { path: '/company/:company_id/record/new', name: 'RecordNew', component: () => import('../views/RecordNew.vue'), meta: { auth: true, breadCrumb: [{ text: 'Editar Cliente'}] } },
  { path: '/company/:company_id/record/edit', name: 'RecordEdit', component: () => import('../views/RecordEdit.vue'), meta: { auth: true, breadCrumb: [{ text: 'Editar Cliente'}] } },

  { path: '/billings', name: 'BillingsView', component: () => import('../views/BillingView.vue'), meta: { auth: true, breadCrumb: [{ text: 'Facturación'}] } },
  { path: '/billing/:id', name: 'BillingView', component: () => import('../views/Billing.vue'), meta: { auth: true, breadCrumb: [{ text: 'Facturación'}] } },
  { path: '/invoiceCompany/:id', name: 'invoiceCompany', component: () => import ('../views/invoiceCompanyShow.vue'), meta: { auth: true, breadCrumb: [{ text: 'Datos proceso'}] } },
  
  { path: '/login', name: 'LoginView', component: () => import('../views/LoginView.vue'), meta: { auth: false } },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.auth) {
    let localUser = auth.getUserLogged()
    if (localUser) {
      next()
    } else {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  } else {
    next()
  }

 /* if ( to.matched.some (record =>  record.meta.auth ) &&  to.meta.auth){ // determine whether the route needs login permission
    let token = localStorage.getItem('token');
    if (token){// gets whether the current token exists
      next()
    } else {
      //There is no token, re authentication is required
      next({
        path: '/auth/login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  } */
  next();
});

export default router
