<template>
<nav class="breadcrumb is-centered has-succeeds-separator" aria-label="breadcrumbs">
  <ul>
    <li><a href="#">Home</a></li>
    <li><a href="#">Documentation</a></li>
    <li><a href="#">Components</a></li>
    <li class="is-active"><a href="#" aria-current="page">Breadcrumb</a></li>
  </ul>
</nav>
</template>

<script>

export default {
  data: () => ({}),
	props: {
		itemList: []
	},
  methods: {}
}
</script>
