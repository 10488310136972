<template>
  <div class="container">
    <form @submit.prevent="cambiarContraseña" class="box">
      <div class="field">
        <label for="password_actual" class="label">Contraseña Actual:</label>
        <div class="control">
          <input type="password" id="password_actual" v-model="current" class="input">
        </div>
      </div>
      <div class="field">
        <label for="nueva_contraseña" class="label">Nueva Contraseña:</label>
        <div class="control">
          <input type="password" id="nueva_contraseña" v-model="newP" class="input">
        </div>
      </div>
      <div class="field">
        <label for="confirmar_contraseña" class="label">Confirmar Nueva Contraseña:</label>
        <div class="control">
          <input type="password" id="confirmar_contraseña" v-model="newConfirm" class="input">
        </div>
      </div>
      <button type="submit" @click.prevent="changePassword()" class="button is-primary">Cambiar Contraseña</button>
    </form>
    <p v-if="mensaje" class="help is-danger">{{ mensaje }}</p>
    <p v-if="mensaje2" class="help is-success">{{ mensaje2 }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: '',
      newP: '',
      newConfirm: '',
      mensaje: '',
      mensaje2: ''
    };
  },
  methods: {
    changePassword() {
      // Aquí debes implementar la lógica para cambiar la contraseña del usuario actual
      // Puedes hacer una petición HTTP a tu backend para manejar esta funcionalidad
      if (this.newP !== this.newConfirm) {
        this.mensaje = 'Las contraseñas no coinciden.';
        return;
      }
      this.mensaje = '';      
      this.mensaje2 = '';  
      this.$axios
        .post('changePassword', { user: { current: this.current, new: this.newP}  })
        .then(response => {
          console.log(response)
          if(response.data.result)
            this.mensaje2 = 'Se ha cambiado la contraseña'
          else
          this.mensaje = 'No puedes cambiar esta contraseña'
          
          // this.$router.push({ name: "CompanyShow", params: { id: newCompany.id }})
        })
        .catch(error => console.log(error))
      this.current = '';
      this.newP = '';
      this.newConfirm = '';
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 50px;
}

.help.is-danger {
  margin-top: 5px;
}
</style>
