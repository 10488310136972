<template>
  <div class="login">
    <form action class="form" @submit.prevent="save">
      <div class="field">
        <label class="label has-text-left" for="#rut">RUT</label>
        <div class="control">
          <input
            v-model="fullRut"
            class="input is-primary"
            type="text"
            id="rut"
            required
            placeholder="RUT"
          >
          <p class=""></p>
        </div>
      </div>
      <div class="field">
        <label class="label has-text-left" for="#name">Nombre</label>
        <div class="control">
          <input
            v-model="company.name"
            class="input is-primary"
            type="text"
            id="name"
            placeholder="Nombre"
          >
        </div>
      </div>
      <p v-if="error" class="error has-text-danger is-size-6">{{ this.strError }}</p>
    </form>
  </div>
</template>

<script>
export default {
  data: () => ({
    company: {
      rut: '', 
      dv: '',
      name: ''
    },
    record: {
      id_remu: '', 
      id_conta: '', 
      tax_regime_id: -1, 
      biller_type_id: -1
    },
    addresses: [],
    partners: [],
    tmpAddress: { name: '', address: '' },
    tmpPartner: { rut: '', name: '', participation_perc: 100, birthday: ''}
  }),
  props: {
      editCompany: Object,
      taxRegimes: Array,
      billerTypes: Array,
      error: Boolean,
      strError: String,
  },
  methods: {
		save () {
      this.$emit('company-form-func', this.company)
			// console.log(this.company)
		},
    eventModal () {
      var Modal = document.querySelector(`#modal`)
      Modal.classList.toggle('is-active')
    },
    eventModalPartners () {
      var Modal = document.querySelector(`#modalPartners`)
      Modal.classList.toggle('is-active')
    },
    addAddress () {
      this.addresses.push({name: this.tmpAddress.name, address: this.tmpAddress.address})
      this.tmpAddress.name = ''
      this.tmpAddress.address = ''
      this.eventModal()
    },
    addPartner () {
      this.partners.push({rut: this.tmpPartner.rut, name: this.tmpPartner.name, participation_perc: this.tmpPartner.participation_perc})
      this.tmpPartner.rut = ''
      this.tmpPartner.name = ''
      this.tmpPartner.participation_perc = ''
      this.eventModalPartners()
    },
    
/*

*/

  },
  watch: { 
		company: {
      handler(newValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.company = newValue
        this.$emit('company-form-func', newValue)
      },
      deep: true
    }
	},
	computed: {
		fullRut: {
			get: function() {
				// return this.company.rut + '-' + this.company.dv
        // console.log(this.company.rut)
        // console.log(this.company.rut.length > 0 ? this.company.rut.replaceAll('-', '') + '-' + this.company.dv : '')
        const rut = new Intl.NumberFormat("es-CL").format(this.company.rut)
        console.log(rut)
        return this.company.rut.length > 0 ? rut + '-' + this.company.dv.replaceAll('-', '') : ''
			},
			set: function (newValue) {
				const local_rut = newValue.replaceAll('.', '').replaceAll('-', '').slice(0, -1)
				const local_dv = newValue.slice(-1).replaceAll('-', '')
				// console.log('rut: ' + local_rut)
				// console.log('dv: ' + local_dv)
				this.company.rut = local_rut
				this.company.dv = local_dv
			}
		}
	}
}
</script>
