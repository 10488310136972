<template>
  <div class="container">
    <div v-if="companies">
      {{ companies.length }}
    </div>
    <div class="columns is-multiline">
      <div v-if="hasWritePermission" class="column is-half">
        <div class="box content is-small">
          <a @click.prevent="showModalFunc()">
            <button class="button is-large">
              <span>
                <i class="fa fa-plus-circle fa-lg"></i>
              </span>
            </button>
          </a>
        </div>
      </div>
      <div class="column is-half" v-for="company in companies" :key="company.id">
        <div class="box content is-small">
          <router-link :to="{ name: 'CompanyShow', params: {id: company.id}}">
            <article class="post">
              <h4>{{ company.name }}</h4>
              <div class="media">
                <div class="media-content">
                  <div class="content">
                    <p>
                      {{ getFullRut(company.rut + company.dv) }} &nbsp;
                      <span v-if="company.balance<0" class="tag is-danger">Saldo: ${{ formatPrice(company.balance) }}</span>
                      <span v-else class="tag is-success">Saldo: ${{ formatPrice(company.balance) }}</span>
                    </p>
                  </div>
                </div>
                <div class="media-right is-medium">
                  <span class="has-text-grey-light">
                    <i v-if="company.rut > 30000000" class="fa fa-building"></i> 
                    <i v-else class="fa fa-user"></i> 
                  </span>
                </div>
              </div>
            </article>
          </router-link>
        </div>
      </div>
    </div>
    <companyForm title='Nuevo Cliente' :visible="showFormModal" @close-modal-func="closeModalFunc" :formName="'companyForm'" @save="saveCompany" :saveLabel="'Crear'" :error="error" :strError="strError" />
  </div>
</template>
<script>
import companyForm from '@/components/modalForm.vue'
export default {
  components: {
		companyForm
	},
  data: () => ({
    showFormModal: false,
    strError: '',
    error: false
  }),
  props: {
    companies: Array,
    hasWritePermission: Boolean
  },
  methods: {
    getFullRut(personRut) {
			let body = personRut.replace('-', '')
			let dv = body[body.length -1 ]
			body = body.substr(0, body.length -1)
			let val = body.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '-' + dv
			return val
		},
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    closeModalFunc() {
      this.showFormModal = false
    },
		showModalFunc() {
      this.showFormModal = true
    },
    async saveCompany (newCompany) {
      console.log(newCompany)
      if(this.validateRut(newCompany.rut + '-' + newCompany.dv))
      {
        this.$axios
        .post('company/create', { company: newCompany })
        .then(response => {
          
          // console.log(response.data)

          const company = response.data.company
          newCompany = company
          this.$router.push({ name: "CompanyShow", params: { id: newCompany.id }})
        })
        .catch(error => console.log(error))
      }
      else {
        this.strError = '*El rut no es válido'
        this.error = true
      }
      
		},
    validateRut(fullrut) {
      fullrut = fullrut.replace("‐","-");
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( fullrut ))
        return false;
      var tmp 	= fullrut.split('-');
      var digv	= tmp[1]; 
      var rut 	= tmp[0];
      if ( digv == 'K' ) digv = 'k' ;
      return (this.validateDv(rut) == digv );
      },
    validateDv(T){
      var M=0,S=1
      for(;T;T=Math.floor(T/10))
        S=(S+T%10*(9-M++%6))%11
      return S?S-1:'k'
    }
  }
}
</script>
