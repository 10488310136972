<template>
<section>
	<div class="modal" id="modal">
		<div class="modal-background"></div>
		<div class="modal-card">
			<header class="modal-card-head">
			<p class="modal-card-title">{{ title }}</p>
			<button class="delete"  @click.prevent="cancelModal()" aria-label="close"></button>
			</header>
			<section class="modal-card-body" v-if="formName === 'billingForm'">
				<billingForm @billing-form-func="returnObject" />
			</section>
			<section class="modal-card-body" v-if="formName === 'companyForm'">
				<companyForm @company-form-func="returnObject" :error="error" :strError="strError" />
			</section>
			<footer class="modal-card-foot">
			<button v-if="this.isProcessing" class="button is-success" disabled @click.prevent="saveObject()">{{ this.saveLabel?this.saveLabel:'Agregar' }}</button>
			<button v-else class="button is-success" @click.prevent="saveObject()">{{ this.saveLabel?this.saveLabel:'Agregar' }}</button>
			<button v-if="this.isProcessing" class="button" disabled @click.prevent="cancelModal()">Cancelar</button>
			<button v-else class="button" @click.prevent="cancelModal()">Cancelar</button>
			</footer>
		</div>
	</div>
	</section>
</template>

<script>
// import { Console } from 'console'
import billingForm from '../components/billing/billingForm.vue'
import companyForm from '@/components/company/CompanyForm.vue'
export default {
	name: 'ModalForm',
	components: {
		billingForm,
		companyForm
	},
  data: () => ({
		resultObject: {},
		convertCurrencies: [],
		isProcessing: false
	}),
  props: {
		title: String,
		visible: Boolean,
		formName: String,
		saveLabel: String,
		error: Boolean,
		strError: String
  },
  methods: {
		cancelModal () { 
			this.$emit('close-modal-func')
		},
    closeModal () {
      var Modal = document.querySelector(`#modal`)
      Modal.classList.toggle('is-active')
			// this.$emit('close-modal-func')
    },
    returnObject (objectModified, valor_uf) {
			// console.log('returnObject')
			// console.log(objectModified)
			this.resultObject = objectModified
			this.convertCurrencies = [{ 'currency_id': 2,  'value_CLP': valor_uf}]
    },
		saveObject () {
			// console.log('saveObject')
			// console.log(this.resultObject)
			this.isProcessing = true
			this.$emit('save', this.resultObject, this.convertCurrencies)
			// this.closeModal()
		}
	},
	mounted() {
    console.log('mounted')
		console.log(this.saveLabel)
  },
	watch: { 
		visible: function(newVal) { // watch it
			console.log(newVal)
			this.closeModal()
		},
		error: function(newVal) {
			if(newVal){
				this.isProcessing = false
			}
		}
	}
}
</script>