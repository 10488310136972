<template>
  <div class="container">
    <label class="label" for="#year">AÑO:</label>
		<input
			v-model="billing.year"
			class="input"
			type="number"
			id="year"
			placeholder="Año"
		>
		<label class="label" for="#mes">MES</label>
		<div class="select is-rounded">
			<select v-model="billing.month" id="mes">
				<option v-for="month in this.months" :key="month.id" v-bind:value="month.id">{{ month.name }}</option>
			</select>
		</div>
		<div class="container">
			<label class="label" for="#currency_UF">Valor UF</label>
			<input
				v-model="currencies_values.value"
				class="input"
				type="number"
				id="currency_UF"
				placeholder="CLP"
			>	
		</div>
  </div>
</template>
<script>
export default {
	name: 'BillingForm',
  data: () => ({
    billing: { year: new Date().getFullYear(), month: new Date().getMonth() + 1 },
		currencies_values: { currency_id: 2, value: '' },
		months: []
  }),
  props: {
    billingEdit: Object,
		currencies: Array
  },
	mounted() {
		this.$emit('billing-form-func', this.billing)
		this.setMonths()
	},
	methods: { 
		setMonths() {
			const months = Array.from({length: 12}, (v, i) => i + 1);
			this.months = months.map(m => this.getMonthObject(m))
		},
		getMonthObject(id){
			return { 'id': id, 'name': this.getMonthName(id, 2023) }
		},
		getMonthName (month, year) {
      const newDate = new Date(year, month - 1, 1)
      const monthName = newDate.toLocaleString('es-cl', { month: 'long' })
      return monthName.charAt(0).toUpperCase() + monthName.slice(1)
    },
    formatPrice(value) {
      let val = (value/1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
	},
  watch: { 
		currencies_values: {
			handler(newValue) {
				this.currencies_values.value = newValue.value
				this.$emit('billing-form-func', this.billing, this.currencies_values.value)
			},
			deep: true
		},
		billing: {
      handler(newValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.billing = newValue
        this.$emit('billing-form-func', newValue, this.currencies_values.value)
      },
      deep: true
    },
		billingEdit: {
			handler(newValue) {
				if(newValue.id) this.billing.id = newValue.id
				this.billing.year = newValue.year
				this.billing.month = newValue.month
			}
			
		}
	},
	computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 1900}, (value, index) => 1901 + index)
    },
  }
}
</script>
