import axios from 'axios'
import Cookies from 'js-cookie'

const ENDPOINT_PATH = process.env.VUE_APP_API_URL

export default {
  setUserLogged (userLogged, token) {
    Cookies.set('userLogged', JSON.stringify(userLogged))
    Cookies.set('jwt-token', token)
  },
  closeSession() {
    Cookies.remove('userLogged')
    Cookies.remove('jwt-token')
  },
  getUserLogged () {
    const userLoggedString = Cookies.get('userLogged');
    return userLoggedString ? JSON.parse(userLoggedString) : null;
  },
  hasUserPermission(permissionCode) {
    const userLogged = this.getUserLogged();
    // Verificar si el usuario está logueado y tiene el permiso específico
    console.log(userLogged.permissions)
    if (userLogged && userLogged.permissions && userLogged.permissions.some(permission => permission.code === permissionCode)) {
      return true;
    }
    return false;
  },
  getTokenLogged () {
    return Cookies.get('jwt-token')
  },
  isLogged () {
    if (this.getTokenLogged) return true
    else {
      this.closeSession()
      return false
    }
  },
  register (email, password) {
    const user = { email, password }
    return axios.post(ENDPOINT_PATH + 'register', user)
  },
  login (email, password) {
    const user = { email, password }
    // console.log(process.env)
    const result = axios.post(ENDPOINT_PATH + 'login', user)
    return result
  }
}
